import React from 'react'
import SchoolInfosNavigation from '../../../components/Infos/SchoolInfos/SchoolInfosNavigation'
import SchoolLayout from '../../../components/schoollayout'

const SchoolTaches = () => (
    <SchoolLayout>
        <div className='school-preparation'>

            <SchoolInfosNavigation />

            <h2>Tâches préparatoires</h2>

            <div className='school-preparation-inner'>

                <h3>Performance</h3>
                <ul>
                    <li>Durée approximative de 25 min par bloc</li>
                </ul>
                <ul className='!ml-[2.5rem]'>
                    <li>SECONDAIRE: 2 blocs de 25 min;</li>
                    <li>PRIMAIRE: 4 blocs de 25 min;</li>
                </ul>
                <ul>
                    <li>Environ 4 à 6 chansons par bloc de 25 min;</li>
                    <li>L'artiste s'installe au même endroit pour toute la durée et les élèves se déplacent pour assister aux prestations;</li>
                    <li>Vous pouvez inviter le nombre de groupe que vous souhaitez à participer;</li>
                    <li>Se déroule habituellement à l’auditorium, salle multi ou espace public (secondaire) et dans un gymnase ou grand local (primaire);</li>
                </ul>

                <h3>ATELIER/JASETTE (SECONDAIRE ET CÉGEP SEULEMENT)</h3>
                <ul>
                    <li>Durée approximative de 60 min;</li>
                    <li>Atelier et/ou jasette, à la discrétion de l’artiste;</li>
                    <li>Inclus une période de questions à la fin (environ 15-20 minutes);</li>
                    <li>Nous recommandons 1 à 3 classes environ afin de permettre aux jeunes d’avoir une réelle proximité avec l’artiste et de favoriser l’échange et l’écoute;</li>
                    <li>Les élèves présents à cette période doivent avoir vu une des performances précédentes si possible;</li>
                    <li>Privilégier les jeunes en concentration musique si applicable, ou du moins qui sont intéressés par la musique;</li>
                    <li>Se déroule habituellement à l’auditorium, mais il est possible de se déplacer dans une classe.</li>
                </ul>
                <br/>
                <p className='!underline'>EXEMPLE DE SUJET ABORDÉ (À LA DISCRÉTION DE L’ARTISTE):</p>
                <ul>
                    <li>Son parcours scolaire</li>
                    <li>Son parcours artistique en général</li>
                    <li>Comment écrire une chanson</li>
                    <li className='!ml-[2.5rem]'>Écriture du texte ou de la musique en premier?</li>
                    <li className='!ml-[2.5rem]'>Son inspiration?</li>
                    <li className='!ml-[2.5rem]'>Ça prend combien de temps?</li>
                    <li>Autres artistes qui l’inspirent?</li>
                    <li>Etc.</li>
                </ul>

                <h3>PÉRIODE DE QUESTIONS (15-20 MINUTES)</h3>
                <p>Merci de faire préparer aux jeunes des questions afin qu’ils soient préparés et intéressés par la venue de l'artiste.</p>

                <h3>TÂCHES PRÉPARATOIRES</h3>
                <p><b>AVANT LA VENUE DU FESTIF À L’ÉCOLE</b></p>
                <ul>
                    <li>Fournir la liste de matériel technique que possède votre école (notre directrice technique vous enverra une fiche à remplir);</li>
                    <li>Faire l’horaire de la demi-journée en collaboration avec le Festif!, selon le gabarit fourni;</li>
                    <li>Préparer les jeunes à la venue de l’artiste - consultez la section “Artistes” de notre site web, (lire sa bio, écouter ses chansons, préparer des questions*);</li>
                    <li>Optionnel: remplir la section “Activités” de notre site web en classe (avant ou après l’activité);</li>
                </ul>
                <p className='mt-2'><b>AVANT LA VENUE DU FESTIF À L’ÉCOLE</b></p>
                <ul>
                    <li>Avoir pris connaissance de l’heure d’arrivée du Festif! et de l’horaire final;</li>
                    <li>S'assurer d’avoir une ressource école présente pour l'accueil de l'équipe ainsi que pendant pour aider à encadrer l’activité, et s’assurer d’avoir un petit local/endroit de disponible pour les effets personnels/pauses de l’artiste;</li>
                </ul>
                <p className='mt-2'><b>PENDANT L‘ACTIVITÉ</b></p>
                <ul>
                    <li>Installer le matériel technique (applicable seulement si l’école a son technicien de son/matériel technique);</li>
                    <li>Accueillir et diriger au bon endroit l’équipe du Festif et l’artiste;</li>
                    <li>Présenter l’artiste devant les groupes au début de chaque performance (vous pouvez vous inspirez de sa bio ou faire ça très simplement);</li>
                    <li>Aider au bon fonctionnement de l’activité (veiller à ce que tous les groupes soient arrivés pour commencer, aider à gérer la période de questions* et veiller à ce que l'artiste soit accueilli dans un climat de respect tout au long de son passage</li>
                    <li>Optionnel: remplir la section “Activités” de notre site web en classe (avant ou après l’activité);</li>
                </ul>

                <div className='text-center mt-16 transform transition-transform hover:scale-105 duration-200 scale-100'>
                    <a className='bg-blue-school text-white rounded-2xl p-4 text-2xl font-ohno'
                       href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZvGmpbVsGrYSvu2g_T%C3%A2chespr%C3%A9paratoiresaux%C3%A9coles.pdf"
                       target="_blank"
                       rel="noreferrer noopener">Imprimer cette page</a>
                </div>

            </div>

        </div>
    </SchoolLayout>
)

export default SchoolTaches
